//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const types = {
  404: {
    img: '/img/404.svg',
    title: '404',
    desc:
      "Sorry, you haven't paid the bill of last month so you cannot use service. Please pay the bill for continue using our service"
  }
};
import { mixin } from '@/utils/mixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Exception',
  mixins: [mixin],
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data() {
    return {
      config: types
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo'])
  },
  created() {
    if (!this.userInfo.company.is_block) {
      this.$router.replace('/');
    }
  },
  methods: {
    ...mapActions('user', ['Logout']),
    handleLogout() {
      this.Logout()
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          this.$message.error({
            title: 'Error',
            description: err.message
          });
        });
    }
  }
};
